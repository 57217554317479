import { debounce } from "throttle-debounce";

var prevScroll;
var temMenuTitle = "";
var whowhoTriggered = false;

function navigateHandler() {
  $(document).on('keydown', function(event) {
    if (event.key == "Escape") {
      if ($(".burger-menu").hasClass("toggler-icon")) {
        toggleMenu();
      }
    }
  });
  if ($("#home-index").length == 0) {
    setDarkNavbar();
  }

  $('.package-footer button').on('click', function() {
    scrollTo('#contact-us', 900);
  });

  $(".home-link").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }
      setLightNavbar();
      $(".page-title").html("home");
      toggleMenu();

      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
    }

    scrollTo("#home");
    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".why-link").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }
      setLightNavbar();
      $(".page-title").html("why");
      toggleMenu();
    }

    scrollTo("#why");
    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".what-link").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }
      setLightNavbar();
      $(".page-title").html("what");
      toggleMenu();
    }

    scrollTo("#what");
    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".who-link").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }
      setDarkNavbar();
      $(".page-title").html("who");
      toggleMenu();
    }

    scrollTo("#who");
    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".case-link-1").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }

      setDarkNavbar();
      $(".page-title").html("case");
      toggleMenu();
    }

    if ($("#home-slider .case-details").hasClass("active")) {
      scrollTo("#caseDetailsContainer");
    } else {
      scrollTo("#case");
    }

    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".case-link-2").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }

      setLightNavbar();
      $(".page-title").html("case");
      toggleMenu();
    }

    if ($("#case-dv-slider .case-details").hasClass("active")) {
      scrollTo("#caseStudyPage2");
    } else {
      scrollTo("#case_dv");
    }

    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".clients-link").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }
      setLightNavbar();
      $(".page-title").html("clients");
      toggleMenu();
    }

    scrollTo("#client");
    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".praise-link").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }
      setDarkNavbar();
      $(".page-title").html("praise");
      toggleMenu();
    }

    scrollTo("#praise");
    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".contact-link").on("click", function () {
    if ($("#home-index").length > 0) {
      if ($(".case-details").hasClass("active")) {
        toggleHomePage();
      }
      setLightNavbar();
      $(".page-title").html("contact");
      toggleMenu();
    }

    scrollTo("#contact-us");
    $(".active-img").addClass("d-none");
    $(this).find(".active-img").removeClass("d-none");
  });

  $(".home-scroll-down").on("click", function () {
    const $container = $("main.snap-container");
    $container.css("scroll-snap-type", "none");

    $("main.snap-container, html, body").animate(
      {
        scrollTop: $container.scrollTop() + $("#why").offset().top,
      },
      400,
      () => $container.css("scroll-snap-type", "y mandatory")
    );
  });

  $(".home-carousel").on("click", function (e) {
    e.stopPropagation();

    var parent = $(this).closest(".case-section");

    $("#home").removeClass("d-flex");
    $("#home").addClass("d-none");
    $("#why").addClass("d-none");
    $("#what").addClass("d-none");
    $("#who").addClass("d-none");
    $("#packages").addClass("d-none");

    $("#client").removeClass("d-flex");
    $("#praise").removeClass("d-flex");
    $("#contact-us").removeClass("d-flex");

    $("#client").addClass("d-none");
    $("#praise").addClass("d-none");
    $("#contact-us").addClass("d-none");
    $("#brand-fact-finder-slider").addClass("d-none");

    $(".disabled-snap-on-case-detail").addClass("d-none");
    $(".snap-child").addClass("snap-disabled");
    $("#praiseSlider .carousel-item").removeClass("carousel-item");

    if (parent.attr("id") == "case") {
      $("#case-dv-slider").addClass("d-none");
      scrollTo("#home-slider", 0);
      $("#home-slider .carousel-control-next").click();
    } else {
      setDarkNavbar();
      $("#home-slider").addClass("d-none");
      scrollTo("#case-dv-slider", 0);
      $("#case-dv-slider .carousel-control-next").click();
    }
  });

  $(".back-carousel").on("click", function (e) {
    e.stopPropagation();

    var parent = $(this).closest(".carousel");

    $("#home").addClass("d-flex");
    $("#home").removeClass("d-none");
    $("#why").removeClass("d-none");
    $("#what").removeClass("d-none");
    $("#who").removeClass("d-none");
    $("#packages").removeClass("d-none");

    $("#client").addClass("d-flex");
    $("#praise").addClass("d-flex");
    $("#contact-us").addClass("d-flex");

    $("#client").removeClass("d-none");
    $("#praise").removeClass("d-none");
    $("#contact-us").removeClass("d-none");
    $("#brand-fact-finder-slider").removeClass("d-none");

    $(".snap-child").removeClass("snap-disabled");

    if (parent.attr("id") == "home-slider") {
      $("#case-dv-slider").removeClass("d-none");
      $("#home-slider .carousel-control-prev").click();
      scrollTo("#case", 0);
    } else {
      setLightNavbar();
      $("#home-slider").removeClass("d-none");
      $("#case-dv-slider .carousel-control-prev").click();
      scrollTo("#case_dv", 0);
    }

    $(".disabled-snap-on-case-detail").removeClass("d-none");
    $("#praiseSlider .bg-dark-grey").addClass("carousel-item");
  });

  $(".pre-roll").on("click", function () {
    if ($(".pre-roll-btn").hasClass("d-none")) {
      $("#pre-roll-audio")[0].pause();
    } else {
      $("#pre-roll-audio")[0].play();
      initPreProgressBar();
    }
    $(".pre-roll-btn").toggleClass("d-none");
    $(".pre-roll-pause-btn").toggleClass("d-none");
  });

  $(".post-roll").on("click", function () {
    if ($(".post-roll-btn").hasClass("d-none")) {
      $("#post-roll-audio")[0].pause();
    } else {
      $("#post-roll-audio")[0].play();
      initPostProgressBar();
    }
    $(".post-roll-btn").toggleClass("d-none");
    $(".post-roll-pause-btn").toggleClass("d-none");
  });

  $(".to-top-container").click(function (event) {
    if ($("#home-index").length > 0) {
      scrollTo("#home", 800);
    } else {
      $("body, html").animate(
        {
          scrollTop: 0,
        },
        800
      );
    }
  });
}

function toggleMenu() {
  var overlay_navigation = $(".overlay-navigation");
  overlay_navigation.removeClass("overlay-active");

  updateBurger();
  toggleNavMenu(false);
}

function toggleSections() {
  $("#home").toggleClass("d-flex");
  $("#home").toggleClass("d-none");
  $("#why").toggleClass("d-none");
  $("#what").toggleClass("d-none");
  $("#who").toggleClass("d-none");
  $("#client").toggleClass("d-none");
  $("#praise").toggleClass("d-none");
  $("#contact-us").toggleClass("d-none");
  $("#brand-fact-finder-slider").toggleClass("d-none");
}

function scrollTo(id = "", scrollSpeed = 0) {
  if ($("#home-index").length > 0) {
    const $container = $("main.snap-container");

    $("body, html, main.snap-container").animate(
      {
        scrollTop: $container.scrollTop() + $(id).offset().top,
      },
      scrollSpeed
    );
  } else {
    window.location = "/" + id;
  }
}

function scrollHandler() {
  var screenWidth = $(window).width();
  var isMobile = screenWidth < 768;
  var navbarHeight = isMobile ? 145 : 246;

  $(window).on("resize", function () {
    screenWidth = $(window).width();
    isMobile = screenWidth < 768;
    navbarHeight = isMobile ? 145 : 246;
  });

  $(window).on("scroll", onScrollPage);
  $("main.snap-container").on("scroll", onScrollPage);
}

function isSectionActive(ele) {
  const rect = ele.getBoundingClientRect();
  const variant = 130
  // console.log(ele)
  return rect.top < variant && rect.bottom > variant;
}

function scaleDownLogo(scrollEvent) {
  if( (/lock-tool/).test($('body').attr('class')) ) { return;}

  let scroll =
    scrollEvent.currentTarget.scrollTop ??
    scrollEvent.currentTarget.scrollY ??
    scrollEvent.currentTarget.pageYOffset;

  if (scroll > 100) {
    $(".main-logo").addClass("scale-down");
  } else {
    $(".main-logo").removeClass("scale-down");
  }
}

function onScrollPage(scrollEvent) {
  // logo animation on scroll
  scaleDownLogo(scrollEvent);

  let activeSection = null;
  let timer = null;

  clearTimeout(timer);

  timer = setTimeout(() => {
    let section = $(".snap-child").filter((_, e) => isSectionActive(e))[0];

    if (section == null || activeSection == section) return; // skip

    activeSection = section;
    let data = activeSection.dataset;

    if (Object.keys(data).length == 0) return;
    // console.log('rect', activeSection.getBoundingClientRect());
    // console.log('data', data);
    data.navbar == "light" ? setLightNavbar() : setDarkNavbar();
    $(".page-title").html(data.menuTitle);

    if (data.menuTitle === "home") {
      $(".navbar-brand .logo").removeClass("smaller");
    } else {
      $(".navbar-brand .logo").addClass("smaller");
    }

    if (data.menuTitle != "who" && $("#who").isInViewport() == false) {
      $("#who-heading").data("whoSection", false);
    }

    var sectionVal = $("#who-heading").data("whoSection");
    if (!whowhoTriggered && data.menuTitle == "who" && sectionVal != true) {
      $("#who-heading").data("whoSection", true);
      animateWhoHeading();
      whowhoTriggered = true;
    }
  }, 10);
}

var scrollToSectionSnap = debounce(500, false, (scroll) => {
  var whyPosition = $("#why").position();
  var whatPosition = $("#what").position();
  var whoPosition = $("#who").position();
  var praisePosition = $("#praise").position();
  var casePosition = $("#case").position();
  var clientPosition = $("#client").position();
  var contactPosition = $("#contact-us").position();

  const isAnySectionTop =
    scroll == 0 ||
    scroll == whyPosition.top ||
    scroll == whoPosition.top ||
    scroll == praisePosition.top ||
    scroll == casePosition.top ||
    scroll == clientPosition.top ||
    scroll == contactPosition.top;

  if ((prevScroll && prevScroll == scroll) || isAnySectionTop) {
    return;
  }

  if (scroll < whyPosition.top / 2) {
    scrollTo("#home", 800);
  } else if (
    scroll < whatPosition.top &&
    whatPosition.top - scroll > scroll - whyPosition.top
  ) {
    scrollTo("#why", 800);
  } else if (
    scroll < whoPosition.top &&
    whoPosition.top - scroll > scroll - whatPosition.top
  ) {
    scrollTo("#what", 800);
  } else if (
    scroll < casePosition.top &&
    casePosition.top - scroll > scroll - whoPosition.top
  ) {
    scrollTo("#who", 800);
  } else if (
    scroll < clientPosition.top &&
    clientPosition.top - scroll > scroll - casePosition.top
  ) {
    scrollTo("#case", 800);
  } else if (
    scroll < praisePosition.top &&
    praisePosition.top - scroll > scroll - clientPosition.top
  ) {
    scrollTo("#client", 800);
  } else if (
    scroll < contactPosition.top &&
    contactPosition.top - scroll > scroll - praisePosition.top
  ) {
    scrollTo("#praise", 800);
  } else {
    if (scroll > contactPosition.top) {
      return;
    }
    scrollTo("#contact-us", 800);
  }

  prevScroll = scroll;
});

function setLightNavbar() {
  $(".nav-dark-wrapper").hide();
  $(".nav-light-wrapper").show();
}

function setDarkNavbar() {
  $(".nav-light-wrapper").hide();
  $(".nav-dark-wrapper").show();
}

function toggleHomePage() {
  if ($("#home-slider .case-details.active").length > 0) {
    $(".snap-child").removeClass("snap-disabled");
    $(".disabled-snap-on-case-detail").removeClass("d-none");

    $("#home-slider .case-details").toggleClass("active");
    $("#home-slider .home-details").toggleClass("active");

    $("#case-dv-slider").removeClass("d-none");
  }

  if ($("#case-dv-slider .case-details.active").length > 0) {
    $(".snap-child").removeClass("snap-disabled");
    $(".disabled-snap-on-case-detail").removeClass("d-none");

    $("#case-dv-slider .case-details").toggleClass("active");
    $("#case-dv-slider .case-dv-home-details").toggleClass("active");

    $("#home-slider").removeClass("d-none");
  }

  $("#praiseSlider .bg-dark-grey").addClass("carousel-item");
  toggleSections();

  $("#praiseSlider .bg-dark-grey").each(function () {
    if (!$(this).hasClass("carousel-item")) {
      $(this).toggleClass("carousel-item");
    }
  });
}

function initPreProgressBar() {
  $("#pre-roll-audio")[0].addEventListener("timeupdate", slidePreProgressBar);
  $("#pre-roll-audio")[0].addEventListener("ended", refreshPreRollAudio);
}

function initPostProgressBar() {
  $("#post-roll-audio")[0].addEventListener("timeupdate", slidePostProgressBar);
  $("#post-roll-audio")[0].addEventListener("ended", refreshPostRollAudio);
}

function slidePreProgressBar() {
  var width = $("#preRollSlider").width();
  var progressbar = $("#preRollSliderMark")[0];
  progressbar.style.marginLeft =
    width *
      ($("#pre-roll-audio")[0].currentTime / $("#pre-roll-audio")[0].duration) +
    "px";
}

function slidePostProgressBar() {
  var width = $("#postRollSlider").width();
  var progressbar = $("#postRollSliderMark")[0];
  progressbar.style.marginLeft =
    width *
      ($("#post-roll-audio")[0].currentTime /
        $("#post-roll-audio")[0].duration) +
    "px";
}

function refreshPreRollAudio() {
  $(".pre-roll-btn").toggleClass("d-none");
  $(".pre-roll-pause-btn").toggleClass("d-none");
  $("#preRollSliderMark")[0].style.marginLeft = "0px";
}

function refreshPostRollAudio() {
  $(".post-roll-btn").toggleClass("d-none");
  $(".post-roll-pause-btn").toggleClass("d-none");
  $("#postRollSliderMark")[0].style.marginLeft = "0px";
}

function updateBurger() {
  $(".burger-menu").toggleClass("toggler-icon");
  $(".burger-menu").toggleClass("menu-icon");

  $(".nav-icon-close-web").toggleClass("open");
  $(".nav-icon-open-web").toggleClass("open");
  $(".nav-icon-close-mobile").toggleClass("open");
  $(".nav-icon-open-mobile").toggleClass("open");
}

function toggleNavMenu(open = true) {
  var overlay_navigation = $(".overlay-navigation"),
    nav_item_1 = $(".overlay-navigation .first-half .menu-item:nth-of-type(1)"),
    nav_item_2 = $(".overlay-navigation .first-half .menu-item:nth-of-type(2)"),
    nav_item_3 = $(".overlay-navigation .first-half .menu-item:nth-of-type(3)"),
    nav_item_4 = $(".overlay-navigation .first-half .menu-item:nth-of-type(4)"),
    nav_item_5 = $(".overlay-navigation .last-half .menu-item:nth-of-type(1)"),
    nav_item_6 = $(".overlay-navigation .last-half .menu-item:nth-of-type(2)"),
    nav_item_7 = $(".overlay-navigation .last-half .menu-item:nth-of-type(3)"),
    nav_item_8 = $(".overlay-navigation .last-half .menu-item:nth-of-type(4)"),
    nav_item_9 = $(".overlay-navigation .last-half .menu-item:nth-of-type(5)"),
    nav_item_10 = $(".overlay-navigation .last-half .menu-item:nth-of-type(6)");

  if (open) {
    temMenuTitle = $(".page-title").html();
    $(".page-title").html("menu");

    overlay_navigation
      .removeClass("overlay-slide-up")
      .addClass("overlay-slide-down");
    nav_item_1
      .removeClass("slide-in-nav-item-reverse")
      .addClass("slide-in-nav-item");
    nav_item_2
      .removeClass("slide-in-nav-item-delay-1-reverse")
      .addClass("slide-in-nav-item-delay-1");
    nav_item_3
      .removeClass("slide-in-nav-item-delay-2-reverse")
      .addClass("slide-in-nav-item-delay-2");
    nav_item_4
      .removeClass("slide-in-nav-item-delay-3-reverse")
      .addClass("slide-in-nav-item-delay-3");
    nav_item_5
      .removeClass("slide-in-nav-item-delay-4-reverse")
      .addClass("slide-in-nav-item-delay-4");
    nav_item_6
      .removeClass("slide-in-nav-item-delay-5-reverse")
      .addClass("slide-in-nav-item-delay-5");
    nav_item_7
      .removeClass("slide-in-nav-item-delay-6-reverse")
      .addClass("slide-in-nav-item-delay-6");
    nav_item_8
      .removeClass("slide-in-nav-item-delay-7-reverse")
      .addClass("slide-in-nav-item-delay-7");
    nav_item_9
      .removeClass("slide-in-nav-item-delay-7-reverse")
      .addClass("slide-in-nav-item-delay-7");
    nav_item_10
      .removeClass("slide-in-nav-item-delay-7-reverse")
      .addClass("slide-in-nav-item-delay-7");
  } else {
    $(".page-title").html(temMenuTitle);

    overlay_navigation
      .removeClass("overlay-slide-down")
      .addClass("overlay-slide-up");
    nav_item_1
      .removeClass("slide-in-nav-item")
      .addClass("slide-in-nav-item-reverse");
    nav_item_2
      .removeClass("slide-in-nav-item-delay-1")
      .addClass("slide-in-nav-item-delay-1-reverse");
    nav_item_3
      .removeClass("slide-in-nav-item-delay-2")
      .addClass("slide-in-nav-item-delay-2-reverse");
    nav_item_4
      .removeClass("slide-in-nav-item-delay-3")
      .addClass("slide-in-nav-item-delay-3-reverse");
    nav_item_5
      .removeClass("slide-in-nav-item-delay-4")
      .addClass("slide-in-nav-item-delay-4-reverse");
    nav_item_6
      .removeClass("slide-in-nav-item-delay-5")
      .addClass("slide-in-nav-item-delay-5-reverse");
    nav_item_7
      .removeClass("slide-in-nav-item-delay-6")
      .addClass("slide-in-nav-item-delay-6-reverse");
    nav_item_8
      .removeClass("slide-in-nav-item-delay-7")
      .addClass("slide-in-nav-item-delay-7-reverse");
    nav_item_9
      .removeClass("slide-in-nav-item-delay-7")
      .addClass("slide-in-nav-item-delay-7-reverse");
    nav_item_10
      .removeClass("slide-in-nav-item-delay-7")
      .addClass("slide-in-nav-item-delay-7-reverse");
  }
}

function handleMenu() {
  $(".burger-menu, #nav-icon-open, #nav-icon-close").click(function () {
    updateBurger();

    var overlay_navigation = $(".overlay-navigation");
    overlay_navigation.toggleClass("overlay-active");
    toggleNavMenu(overlay_navigation.hasClass("overlay-active"));
  });
}

function animateWhoHeading() {
  var lines = ["(who", "(who, who", "(who, who, who", "(who, who, who, who?)"];
  var timer,
    displayLine = function () {
      var nextLine = lines.shift();
      if (nextLine) {
        var newLine = nextLine;
        $("#who-heading").text(newLine);
        if (newLine == "(who, who") {
          var timeout = 750;
        } else {
          var timeout = 500;
        }
        timer = setTimeout(displayLine, timeout);
      }
    };
  timer = setTimeout(displayLine, 1000);
}

$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

export { navigateHandler, scrollHandler, handleMenu, scrollTo };
